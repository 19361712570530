<script>
import axios from "axios";
import getLanguage from "@/helpers/i18n-helper";
export default {
  methods: {
    register: async function() {
      const display_name = this.$refs.register_display_name.value;
      const organization = this.$refs.register_organization.value;
      const email = this.$refs.register_email.value;
      // const newsletter = this.$refs.register_newletter.checked;
      const terms = this.$refs.register_terms.checked;
      if (display_name === "") {
        alert(this.$t("t-register-validate-display-name"));
        return;
      }
      if (organization === "") {
        alert(this.$t("t-register-validate-organization"));
        return;
      }
      if (email === "") {
        alert(this.$t("t-register-validate-email"));
        return;
      }
      if (!terms) {
        alert(this.$t("t-register-validate-terms"));
        return;
      }
      const button = this.$refs.register_button;
      const spinner = this.$refs.register_button_spinner;
      button.disable = true;
      spinner.style.display = "inline-block";
      try {
        let data = {
          email: email,
          organization: organization,
          display_name: display_name,
          lang: getLanguage()
        };
        if (this.distributor_id) {
          data["distributor_id"] = this.distributor_id;
        }
        const result = await axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/auth/register', data);
        if (result.status === 201) {
          window.location.href = "/account/login";
        }
      } catch (err) {
        console.log(err);
      } finally {
        button.disable = false;
        spinner.style.display = "none";
      }
    }
  },
  data() {
    return {
      distributor_id: null
    };
  },
  mounted() {
    this.distributor_id = this.$route.params.distributor_id;
  },
  beforeMount() {

  }
}
</script>

<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>

            <div class="shape">

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-light.png" alt="" height="28">
                                </router-link>
                            </div>
                            <p class="mt-3 mb-1 fs-18 fw-medium">{{ $t("t-register-heading") }}</p>
                          <p class="fs-14 fw-medium">{{ $t("t-register-heading-subtitle") }}</p>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">

                            <div class="card-body p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">{{ $t("t-register-welcome-title") }}</h5>
                                    <p class="text-muted">{{ $t("t-register-welcome-subtitle") }}</p>
                                </div>
                                <div class="p-2 mt-4">
                                    <form class="needs-validation" novalidate @submit.prevent="register">

                                      <div class="mb-3">
                                        <input ref="register_display_name" type="text" class="form-control" id="display-name"
                                               :placeholder="$t('t-register-display-name')" required>
                                        <div class="invalid-feedback">
                                          {{ $t("t-register-validate-display-name") }}
                                        </div>
                                      </div>

                                      <div class="mb-3">
                                            <input ref="register_organization" type="text" class="form-control" id="organization"
                                                :placeholder="$t('t-register-organization')" required>
                                            <div class="invalid-feedback">
                                              {{ $t("t-register-validate-organization") }}
                                            </div>
                                        </div>

                                      <div class="mb-3">
                                        <input ref="register_email" type="email" class="form-control" id="useremail"
                                               :placeholder="$t('t-register-email')" required>
                                        <div class="invalid-feedback">
                                          {{ $t("t-register-validate-email") }}
                                        </div>
                                      </div>

                                      <div class="mb-4">
                                          <div class="form-group fs-12 text-muted">
                                            <label for="personal-terms"><span><input ref="register_terms" id="personal-terms" name="personal-terms" type="checkbox"></span> <span v-html="$t('t-register-terms')"></span></label><br>
<!--                                            <label for="personal-updates"><span><input ref="register_updates" id="personal-updates" name="personal-updates" type="checkbox"></span> <span v-html="$t('t-register-updates')"></span></label><br>-->
                                          </div>
                                        </div>
                                        <div class="mt-4">
                                            <a ref="register_button" class="btn btn-success w-100" @click="register">
                                              <span ref="register_button_spinner" class="spinner-border spinner-border-sm" style="display: none;" role="status" aria-hidden="true"></span>
                                              {{ $t("t-register-button") }}
                                            </a>
                                        </div>

                                    </form>

                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->

                        <div class="mt-4 text-center">
                            <p class="mb-0">{{ $t("t-register-account-1") }} <a href="/account/login"
                                    class="fw-semibold text-primary text-decoration-underline"> {{ $t("t-register-account-2") }} </a>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">&copy; {{new Date().getFullYear()}} Virtual Bodyguard BV</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>